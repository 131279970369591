



















































































import { LoadingStatusType, TableSorting } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { PromoCampaignModel, PromoCodeListRequestModel, PromoCodeModel } from "@/model/promoCode/promoCodeModel";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PromoCodeCreate from "./promoCodeCreate/PromoCodeCreate.vue";
import AuthService from "@/service/authService";

const auth = new AuthService();
const StorePromoCode = namespace("PromoCode");

@Component({ components: { PromoCodeCreate } })
export default class PromoCodeTable extends Vue {
  private textSearchPromoCode = "";
  private textSearchPromoCampaign = "";
  private table: any = {
    header: [],
    detail: [],
  };
  private action: any = [{ actionValue: 1, text: "แก้ไข" }];
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;
  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];
  private isShowModalPromoCodeCreate = false;
  permission = false;

  @StorePromoCode.Getter
  private getPromoCodeList!: Array<PromoCodeModel>;
  @StorePromoCode.Getter
  private getHeaderPromoCodeTable!: Array<any>;
  @StorePromoCode.Getter
  private getCountPromoCodeItem!: number;
  @StorePromoCode.Getter
  private getPromoCodeListLoadingStatus!: LoadingStatusType;
  @StorePromoCode.Getter
  private getCreatePromoCodeLoadingStatus!: LoadingStatusType;
  @StorePromoCode.Getter
  private getPromoCampaignList!: Array<PromoCampaignModel>;
  @StorePromoCode.Getter
  private getPromoCampaignListLoadingStatus!: LoadingStatusType;

  @StorePromoCode.Action
  private DoGetPromoCodeList!: (input: PromoCodeListRequestModel) => void;
  @StorePromoCode.Action
  private DoGetPromoCampaignList!: () => void;

  constructor() {
    super();
    this.getPromoCodePermissions();
  }

  created() {
    this.GetPromoCodeList(this.pageNo, this.pageSize);
    this.DoGetPromoCampaignList();
  }

  @Watch("getPromoCodeListLoadingStatus", { immediate: true })
  getPromoCodeListLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.table.header = NewObject(this.getHeaderPromoCodeTable);
      this.table.detail = this.getPromoCodeList.length > 0 ? NewObject(this.getPromoCodeList) : [];
      this.countItem = this.getCountPromoCodeItem;
      this.totalPage = Math.ceil(this.countItem / this.pageSize);
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }

  @Watch("getCreatePromoCodeLoadingStatus")
  getCreatePromoCodeLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.GetPromoCodeList(1, this.pageSize);
    }
  }

  @Watch("pageNo")
  pageNoChanged(newVal: number) {
    this.GetPromoCodeList(this.pageNo, this.pageSize);
  }

  @Watch("pageSize")
  pageSizeChanged(newVal: number) {
    this.GetPromoCodeList(1, this.pageSize);
  }

  public GetPromoCodeList(pageNo = this.pageNo, pageSize = this.pageSize) {
    const promoCodeListRequest: PromoCodeListRequestModel = {
      pageNo: pageNo,
      pageSize: pageSize,
      tableSorting: TableSorting.ASC,
      promoCode: this.textSearchPromoCode,
      promoCampaign: this.textSearchPromoCampaign,
      isExpired: false,
      isFullyUsed: false,
    };
    this.DoGetPromoCodeList(promoCodeListRequest);
  }

  public SearchPromoCode() {
    this.pageNo = 1;
    this.pageSize = 10;
    this.GetPromoCodeList(this.pageNo, this.pageSize);
  }

  public GetPromoCampaignName(campaignId: number) {
    let campaignName = "";
    if (this.getPromoCampaignListLoadingStatus === LoadingStatusType.Success) {
      const campaignById = this.getPromoCampaignList.find((x: any) => x.id === campaignId);
      if (campaignById && campaignById.id === campaignId) {
        campaignName = campaignById.name;
      }
    }
    return campaignName;
  }

  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }
  public async getPromoCodePermissions() {
    const accessPermission = await auth.getPermissions();
    this.permission = accessPermission["promoCode"]["create"];
  }
}
