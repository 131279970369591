




































import { CreatePromoCodeType, LoadingStatusType } from "@/enums/enums";
import { Component, Vue, Watch } from "vue-property-decorator";
import PromoCodeAdvanceCreate from "./PromoCodeAdvanceCreate.vue";
import PromoCodeListCreate from "./PromoCodeListCreate.vue";
import {
  ConvertToPromoCodeCreateListRequest,
  ConvertToPromoCodeCreateRequest,
  GetPromoCodeCreateTemplateDefault,
  ValidateCreatePromoCode,
} from "@/helper/promoCodeHelper";
import { namespace } from "vuex-class";
import { PromoCodeCreateListRequest, PromoCodeCreateRequest } from "@/model/promoCode/promoCodeModel";

const StorePromoCode = namespace("PromoCode");

@Component({ components: { PromoCodeAdvanceCreate, PromoCodeListCreate } })
export default class PromoCodeCreate extends Vue {
  private CreatePromoCodeType = CreatePromoCodeType;

  private showModal = true;
  private type = CreatePromoCodeType.List;
  private promoCodeCreateTemplate = GetPromoCodeCreateTemplateDefault();

  @StorePromoCode.Getter
  private getCreatePromoCodeLoadingStatus!: LoadingStatusType;

  @StorePromoCode.Action
  private DoCreatePromoCode!: (input: PromoCodeCreateRequest) => void;
  @StorePromoCode.Action
  private DoCreateListPromoCode!: (input: PromoCodeCreateListRequest) => void;

  @Watch("showModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.HideModal();
    }
  }

  public CreatePromoCode() {
    const isValidateError = ValidateCreatePromoCode(this.promoCodeCreateTemplate, this.type);
    if (!isValidateError) {
      switch (this.type) {
        case CreatePromoCodeType.Advance: {
          const promoCodeRequest = ConvertToPromoCodeCreateRequest(this.promoCodeCreateTemplate);
          this.DoCreatePromoCode(promoCodeRequest);
          break;
        }
        case CreatePromoCodeType.List: {
          const promoCodeRequest = ConvertToPromoCodeCreateListRequest(this.promoCodeCreateTemplate);
          this.DoCreateListPromoCode(promoCodeRequest);
          break;
        }
      }

      this.showModal = false;
    }
  }

  public HideModal() {
    this.$emit("update:showModal", false);
  }
}
