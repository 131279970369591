
























































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PromoCodeConditionCreate extends Vue {
  @Prop({}) promoCodeCreateTemplate!: any;
}
