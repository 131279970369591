






import { Component, Vue } from "vue-property-decorator";
import PPromoCodeTable from "./PromoCodeTable.vue";

@Component({ components: { PPromoCodeTable } })
export default class PromoCode extends Vue {}
