














































































































import { Component, Prop, Vue } from "vue-property-decorator";
import DropdownCampaign from "@/components/dropdown/DropdownCampaign.vue";
import PromoCodeConditionCreate from "./PromoCodeConditionCreate.vue";
import { ValidateCreatePromoCode } from "@/helper/promoCodeHelper";
import { CreatePromoCodeType } from "@/enums/enums";

@Component({ components: { DropdownCampaign, PromoCodeConditionCreate } })
export default class PromoCodeAdvanceCreate extends Vue {
  @Prop({}) promoCodeCreateTemplate!: any;

  private modalStartDate = false;
  private modalExpireDate = false;

  get minDate() {
    const date = new Date(this.promoCodeCreateTemplate.startDate.value);
    return new Date(date.setDate(date.getDate() + 1)).toISOString().slice(0, 10);
  }

  public OnChangedCreatePromoCode() {
    ValidateCreatePromoCode(this.promoCodeCreateTemplate, CreatePromoCodeType.Advance);
  }
}
